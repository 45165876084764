
#user {
  background: none;
  border: none;
  border-radius: 30px;
  padding: 0;
}

#user img {
  border-radius: 30px;
  height: 36px;
}

.navbar-expand-md .navbar-nav .dropdown-menu {
    position: fixed;
    padding: 20px;
    right: 20px;
    top: 60px;
    width: fit-content;
    left: auto;
}