
form.contact-form {
  max-width: 720px;
  margin-top: 6rem;
}

form.contact-form label.hide {
  display: none;
}

form.contact-form button {
  width: 200px;
}

form.contact-form svg {
  margin: 0 8px;
}

#root form.contact-form .alert {
  border-radius: 8px;
}